<template>
  <collapse-card :model-value="true">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        SKU
      </span>
    </template>

    <template #cardContent>
      <div
        v-if="isValidatingSkus"
        class="h-12 w-full mt-4 text-center"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Validating SKU...
      </div>
      <div
        v-else
        class="py-2 px-4"
      >
        <!-- sku table starts -->
        <div class="flex mb-3">
          <icon-button
            class="mr-3 w-7.5 h-7.5"
            icon="icons/plus.svg"
            :active="isAddSkuButtonActive"
            active-btn-class="bg-custom-green-1 bg-opacity-20"
            active-icon-class="text-custom-green-1"
            @click="setupNewSkuForm()"
          />
          <icon-button
            class="w-7.5 h-7.5"
            icon="icons/x.svg"
            :active="isDeleteSkuButtonActive"
            active-btn-class="bg-primary-red bg-opacity-20"
            active-icon-class="text-primary-red"
            @click="deleteSKU()"
          />
          <div
            v-if="skuTableData && skuTableData.data.length && !licenseeCanHaveMultipleSkusPerSubmission"
            class="ml-8 py-2 px-1 italic"
          >
            Only a single SKU can be specified for the submission.
          </div>
        </div>
        <brand-assurance-table
          v-model="skuTableData"
          root-element-class="mb-10"
          :error="submissionFormValidations.skuTable || submissionFormValidations.skuUpcTable"
        >
          <!-- inject button in each cell of view/edit column -->
          <template
            v-for="(skuObj, cIndex) in skuTableData.data"
            :key="'cell-slot' + cIndex + '3'"
            #[generateTableSlotName(cIndex,3)]
          >
            <base-button
              variant="btn-link"
              text="View/Edit"
              type="button"
              @click="editSKU(skuObj, cIndex)"
            />
          </template>
        </brand-assurance-table>
        <!-- sku table ends -->
        <!-- SKU form starts -->
        <template v-if="skuFormMode">
          <div class="flex mb-5 mt-3">
            <base-button
              variant="btn-primary"
              class="mr-3"
              :is-loading="isValidatingSkus"
              text="Save"
              type="button"
              :disabled="disabledSaveSkuFieldState === VIEW_ONLY"
              @click="saveSKU()"
            />
            <base-button
              variant="btn-link"
              text="Cancel"
              type="button"
              @click="cancelSKU()"
            />
          </div>
          <base-input
            v-model="selectedSKU.skuNo"
            type="text"
            container-class="flex items-center mb-4"
            label="SKU No."
            label-class="label-w-46"
            :error="selectedSKU.skuNo == '' ? 'Required Field' : isSkuNumberRepeated ? `SKU: ${selectedSKU.skuNo} is duplicated.`: '' "
            :disabled="skuFormMode === 'EDIT'"
          />
          <base-input
            v-model="selectedSKU.skuComments"
            type="text"
            container-class="flex items-center mb-6"
            label="SKU Description"
            label-class="label-w-46"
          />
          <h5 class="mb-3 text-black text-center font-semibold">
            CHARACTER
          </h5>
          <!--- Only Sinlge SKU allowed .. all SG characters are assigned to the SKU-->
          <div
            v-if="!licenseeCanHaveMultipleSkusPerSubmission"
            class="w-full flex flex-col pb-8"
          >
            <div
              v-if="!styleGuideCharacterData || !styleGuideCharacterData.length"
              class="p-4 border border-custom-gray-8 w-full italic"
            >
              No Style Guide Characters have been assigned to the submission
            </div>
            <div v-else>
              <div class="bg-custom-gray-10 p-2 text-left text-xs font-medium tracking-wider text-black whitespace-nowrap">
                Style Guide Character
              </div>
              <div
                v-for="(sgChar,charIdx) in styleGuideCharacterData.filter(s => s.characterName)"
                :key="charIdx"
                class="p-2 text-left text-sm font-medium text-black border-b border-r border-l border-custom-gray-8 flex flex-row flex-wrap"
              >
                <div
                  v-if="sgChar.characterName"
                  class="w-1/2 truncate"
                >
                  <span class="font-semibold">Character Name</span>: {{sgChar.characterName}}
                </div>
                <div
                  v-if="sgChar.isActorLikenessProduct!=null || sgChar.isActorLikenessPackaging!=null || sgChar.isActorLikenessVoice!=null"
                  class="w-1/2 truncate flex justify-center content-center"
                >
                  <span class="font-semibold px-4 py-1 mr-4 bg-custom-gray-10 border-r">Actor Likeness</span>
                  <span>Product</span><span class="mx-8">{{sgChar.isActorLikenessProduct ? '✓' : '—'}}</span>
                  <span>Packaging</span><span class="mx-8">{{sgChar.isActorLikenessPackaging ? '✓' : '—'}}</span>
                  <span>Voice</span><span class="mx-8">{{sgChar.isActorLikenessVoice ? '✓' : '—'}}</span>
                </div>
              </div>
            </div>
          </div>
          <!--- Multiple SKUs are enabled .. each sku can have different characters -->
          <div v-else>
            <div class="flex mb-3">
              <icon-button
                class="mr-3 w-7.5 h-7.5"
                icon="icons/plus.svg"
                :active="isAddCharacterButtonActive"
                active-btn-class="bg-custom-green-1 bg-opacity-20"
                active-icon-class="text-custom-green-1"
                @click="addCharacterTableRow()"
              />
              <icon-button
                class="w-7.5 h-7.5"
                icon="icons/x.svg"
                :active="isDeleteCharacterButtonActive"
                active-btn-class="bg-primary-red bg-opacity-20"
                active-icon-class="text-primary-red"
                @click="deleteCharacterTableRows()"
              />
            </div>
            <brand-assurance-table
              v-if="selectedSKU && selectedSKU.characterTableData"
              v-model="selectedSKU.characterTableData"
              :are-columns-sortable="false"
              root-element-class="mb-1"
              :error="submissionFormValidations.skuCharacters"
            >
              <!-- show 1 empty row if table is empty -->
              <template
                v-if="selectedSKU.characterTableData.data.length === 0"
                #customTableBody
              >
                <tr>
                  <td
                    class="p-2 whitespace-nowrap text-center border border-r-0 border-b-0 last:border-r border-custom-gray-8 text-xs"
                  >
                      &nbsp;
                  </td>
                  <td
                    v-for="(_, tdIndex) in selectedSKU.characterTableData.columns"
                    :key="'cell' + tdIndex"
                    class="p-2 whitespace-nowrap border border-r-0 border-b-0 last:border-r border-custom-gray-8 text-xs"
                  >
                      &nbsp;
                  </td>
                </tr>
              </template>
              <!-- inject dropdown in each cell of style guide character column -->
              <template
                v-for="(characterObj, cIndex) in selectedSKU.characterTableData.data"
                :key="'cell-slot' + cIndex"
                #[generateTableSlotName(cIndex,1)]
              >
                <base-select
                  :model-value="characterObj.characterName"
                  :options="skuCharacterOptions?.length > 0 ? skuCharacterOptions : ['']"
                  dropdown-container-class="max-w-3xl"
                  dropdown-btn-container-class="w-full static"
                  placeholder="-- SELECT --"
                  class="flex items-center text-sm text-left"
                  show-default-option
                  @update:modelValue="handleCharacterSelection($event, cIndex)"
                />
              </template>
              <!-- inject checkboxes in required columns when selected style guide is a movie -->
              <template
                v-for="(characterObj, cIndex) in selectedSKU.characterTableData.data"
                :key="'cell-slot' + cIndex"
                #[generateTableSlotName(cIndex,2)]
              >
                <base-checkbox
                  :model-value="characterObj.isActorLikenessProduct"
                  @update:modelValue="handleFlagSelection($event, 'isActorLikenessProduct', cIndex)"
                />
              </template>
              <template
                v-for="(characterObj, cIndex) in selectedSKU.characterTableData.data"
                :key="'cell-slot' + cIndex"
                #[generateTableSlotName(cIndex,3)]
              >
                <base-checkbox
                  :model-value="characterObj.isActorLikenessPackaging"
                  @update:modelValue="handleFlagSelection($event, 'isActorLikenessPackaging', cIndex)"
                />
              </template>
              <template
                v-for="(characterObj, cIndex) in selectedSKU.characterTableData.data"
                :key="'cell-slot' + cIndex"
                #[generateTableSlotName(cIndex,4)]
              >
                <base-checkbox
                  :model-value="characterObj.isActorLikenessVoice"
                  @update:modelValue="handleFlagSelection($event, 'isActorLikenessVoice', cIndex)"
                />
              </template>
            </brand-assurance-table>
            <span
              v-if="[OPA_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName) && selectedSKU.characterTableData.data.length === 0"
              class="text-red-600 bg-purple-300"
            >
              Please add at least one SKU style guide Character
            </span>
          </div>
          <base-input
            v-if="numberOfSampleReceivedFieldState !== INVISIBLE"
            v-model="selectedSKU.samplesReceived"
            type="text"
            container-class="flex items-center mb-4 mt-6"
            label="Number of Samples Received"
            label-class="label-w-46"
          />

          <h5 class="mb-3 text-black text-center font-semibold">
            UPC/ISBN/EAN
          </h5>

          <div class="flex mb-3">
            <icon-button
              class="mr-3 w-7.5 h-7.5"
              icon="icons/plus.svg"
              :active="selectedSKU.upcOptOut === 'N'"
              active-btn-class="bg-custom-green-1 bg-opacity-20"
              active-icon-class="text-custom-green-1"
              @click="addUpcTableRow()"
            />
            <icon-button
              class="w-7.5 h-7.5"
              icon="icons/x.svg"
              :active="isDeleteUpcButtonActive && selectedSKU.upcOptOut === 'N'"
              active-btn-class="bg-primary-red bg-opacity-20"
              active-icon-class="text-primary-red"
              @click="deleteUpcTableRows()"
            />
          </div>

          <brand-assurance-table
            v-model="selectedSKU.upcTableData"
            root-element-class="mb-10"
          >
            <!-- show 1 empty row if table is empty -->
            <template
              v-if="selectedSKU.upcTableData.data.length === 0 && selectedSKU.upcOptOut === 'N'"
              #customTableBody
            >
              <tr>
                <td
                  class="p-2 whitespace-nowrap text-center border border-r-0 border-b-0 last:border-r border-custom-gray-8 text-xs"
                >
                    &nbsp;
                </td>
                <td
                  v-for="(_, tdIndex) in selectedSKU.upcTableData.columns"
                  :key="'cell' + tdIndex"
                  class="p-2 whitespace-nowrap border border-r-0 border-b-0 last:border-r border-custom-gray-8 text-xs"
                >
                    &nbsp;
                </td>
              </tr>
            </template>
            <!-- inject dropdown in each cell of type column -->
            <template
              v-for="(upcObj, cIndex) in selectedSKU.upcTableData.data"
              :key="'cell-slot' + cIndex + '1'"
              #[generateTableSlotName(cIndex,1)]
            >
              <base-select
                v-model="upcObj.upcEanIsbn"
                class="flex items-center text-sm text-left"
                placeholder="-- SELECT --"
                dropdown-btn-container-class="w-full static"
                dropdown-container-class="max-w-3xl"
                :options="upcTypeOptions"
                item-text-property="name"
                item-value-property="value"
                show-default-option
              />
            </template>
            <!-- inject input in each cell of value column -->
            <template
              v-for="(upcObj, cIndex) in selectedSKU.upcTableData.data"
              :key="'cell-slot' + cIndex + '2'"
              #[generateTableSlotName(cIndex,2)]
            >
              <base-input
                v-model="upcObj.upcValue"
                type="text"
                container-class="w-full flex items-center"
                :error="submissionFormValidations.upcValueVaidation[cIndex]"
                :error-text="upcValidationErrors[cIndex]"
              />
            </template>
            <!-- inject dropdown in each cell of description column -->
            <template
              v-for="(upcObj, cIndex) in selectedSKU.upcTableData.data"
              :key="'cell-slot' + cIndex + '3'"
              #[generateTableSlotName(cIndex,3)]
            >
              <base-input
                v-model="upcObj.upcDescription"
                type="text"
                container-class="w-full flex items-center"
              />
            </template>
            <!-- inject dropdown in each cell of retailer column -->
            <template
              v-for="(upcObj, cIndex) in selectedSKU.upcTableData.data"
              :key="'cell-slot' + cIndex + '4'"
              #[generateTableSlotName(cIndex,4)]
            >
              <base-input
                v-model="upcObj.retailor"
                type="text"
                container-class="w-full flex items-center"
              />
            </template>
          </brand-assurance-table>

          <span
            v-if="submissionFormValidations.upcGlobalValidation"
            class="text-red-600"
          >
            Please add at least one UPC/ISBN/EAN.
          </span>

          <div class="flex flex-row">
            <base-checkbox
              v-if="upcOptOutFieldState !== INVISIBLE"
              :model-value="selectedSKU.upcOptOut"
              container-class="w-1/4 flex my-2"
              value="Y"
              unchecked-value="N"
              @update:modelValue="handleUpcOptOut($event)"
            >
              <template #label>
                <label class="ml-2 text-black text-sm">
                  UPC Opt-Out
                </label>
              </template>
            </base-checkbox>

            <base-input
              v-if="selectedSKU.upcOptOut === 'Y'"
              v-model="selectedSKU.upcOptOutComments"
              text-area
              rows="3"
              container-class="w-3/4 my-2 flex"
              label-class="label-w-46"
              label="UPC Opt-Out Justification"
              :error="submissionFormValidations.upcOptOutComments"
              :is-required="optOutJustificationFieldState === REQUIRED"
            />
          </div>

          <div class="flex flex-row">
            <base-checkbox
              v-if="optOutApprovalFieldState !== INVISIBLE"
              :model-value="selectedSKU.upcOptOutApproval"
              container-class="w-1/4 flex my-2"
              value="Y"
              unchecked-value="N"
              @update:modelValue="handleUpcOptOutApproval($event)"
            >
              <template #label>
                <label class="ml-2 text-black text-sm">
                  UPC Opt-Out Approval
                </label>
              </template>
            </base-checkbox>

            <base-input
              v-if="selectedSKU.upcOptOutApproval === 'Y' && optOutApprovalFieldState !== INVISIBLE"
              v-model="selectedSKU.upcOptOutApprovalComments"
              text-area
              rows="3"
              container-class="w-3/4 my-2 flex"
              label-class="label-w-46"
              label="UPC Opt-Out Approval Comments"
            />
          </div>
        </template>
        <!-- SKU form ends -->
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent, ref, computed, reactive, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import useBaTable from '@/hooks/baTable.js';
import useSubmissionForm from './submissionForm.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import useUserAuth from '@/hooks/userAuth.js';
import { ANNUALS_STAGE, CONTRACTUAL_STAGE, GENERIC_SUBMISSION_TYPE, INVISIBLE, PRESS_RELEASE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE, PRODUCTION_STAGE, STANDARD_SUBMISSION_TYPE, REQUIRED, EDITABLE, LICENSEE_STEP, INTERACTIVE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE, PRINT_ON_DEMAND_SAMPLE_METARIAL, VIEW_ONLY } from '@/constants/submissions.js';
import { ADD_ATLEAST_ONE_CHARACTER, REQUIRED_FIELD } from '@/constants/validationMessages.js';
import { ENTER_SKU_NUMBER, ERROR, SKU_VALIDATION_FAILED, WARNING } from '@/constants/alerts.js';
import { SUBMISSION_FORM_OPT_APPROVAL_PERMISSION } from '@/constants/permissions.js';

export default {
    name: 'SkuSection',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BaseCheckbox: defineAsyncComponent(() => import('@/components/generic-components/BaseCheckbox.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    setup () {
        const store = useStore();
        const { submissionForm, updateSubmissionForm, submissionFormMode, submissionFormValidations } = useSubmissionForm();
        const { generateTableSlotName } = useBaTable();
        const { notificationsStack } = useToastNotifications();
        const { userPermissions } = useUserAuth();

        // SKU's logic
        const selectedSKUIndex = ref(0);
        const selectedSKU = ref(null);
        const skuFormMode = ref(null);

        const skuTableData = reactive({
            columns: [
                {
                    label: 'SKU NO',
                    key: 'skuNo'
                },
                {
                    label: 'SKU Comments',
                    key: 'skuComments'
                },
                {
                    label: 'View or Edit',
                    key: ''
                }
            ],
            data: submissionForm.skus.map((sku) => {
                sku.characters = sku.characters.map(c => {
                    const tmpChar = { ...c };
                    if (typeof tmpChar.isActorLikenessPackaging === 'string') tmpChar.isActorLikenessPackaging = tmpChar.isActorLikenessPackaging === 'Y';
                    if (typeof tmpChar.isActorLikenessProduct === 'string') tmpChar.isActorLikenessProduct = tmpChar.isActorLikenessProduct === 'Y';
                    if (typeof tmpChar.isActorLikenessVoice === 'string') tmpChar.isActorLikenessVoice = tmpChar.isActorLikenessVoice === 'Y';
                    return tmpChar;
                });
                return {
                    selected: false,
                    ...sku
                };
            })
        });

        const styleGuideCharacterData = computed(() => {
            return [
                ...submissionForm.characters.map(c => {
                    const tmpChar = { ...c };
                    if (typeof tmpChar.isActorLikenessPackaging === 'string') tmpChar.isActorLikenessPackaging = tmpChar.isActorLikenessPackaging === 'Y';
                    if (typeof tmpChar.isActorLikenessProduct === 'string') tmpChar.isActorLikenessProduct = tmpChar.isActorLikenessProduct === 'Y';
                    if (typeof tmpChar.isActorLikenessVoice === 'string') tmpChar.isActorLikenessVoice = tmpChar.isActorLikenessVoice === 'Y';
                    return tmpChar;
                })
            ];
        });

        // -------------------------------
        // ----------  SKU FORM  ---------
        // -------------------------------

        const setupNewSkuForm = () => {
            resetSKUForm();
            selectedSKU.value = {
                skuNo: '',
                skuComments: '',
                characters: [],
                upcOptOut: submissionForm.submissionTypeName === OPA_SUBMISSION_TYPE ? 'Y' : 'N',
                upcOptOutComments: submissionForm.submissionTypeName === OPA_SUBMISSION_TYPE ? 'N/A' : '',
                upcs: [],
                upcOptOutApproval: 'N',
                upcOptOutApprovalComments: '',
                submissionId: submissionForm.submissionId,
                samplesReceived: null,
                iteration: submissionForm.iteration,
                currentStage: submissionForm.currentStage,
                currentStep: submissionForm.currentStep,
                characterTableData: {
                    columns: makeCharacterTableColumns(submissionForm.styleGuideName),
                    data: []
                },
                upcTableData: {
                    columns: [
                        {
                            label: 'Type',
                            key: 'upcEanIsbn'
                        },
                        {
                            label: 'Value',
                            key: 'upcValue'
                        },
                        {
                            label: 'Description',
                            key: 'upcDescription'
                        },
                        {
                            label: 'Retailer',
                            key: 'retailor'
                        }
                    ],
                    data: []
                }
            };
            selectedSKUIndex.value = skuTableData.data.length ? skuTableData.data.length - 1 : 0;
            skuFormMode.value = 'NEW';
        };

        const deleteSKU = () => {
            // if an sku item while editing is deleted, reset sku form
            if (skuFormMode.value === 'EDIT' && skuTableData.data[selectedSKUIndex.value].selected) resetSKUForm();
            skuTableData.data = skuTableData.data.filter(tableObj => !tableObj.selected);        
            updateSkusInSubmissionForm();
        };

        const editSKU = (skuObj, skuIndex) => {
            resetSKUForm();

            selectedSKU.value = {
                ...skuObj,
                characterTableData: {
                    columns: makeCharacterTableColumns(submissionForm.styleGuideName),
                    data: skuObj.characters?.filter(e => e.characterName).map(c => {
                        const tmpChar = { ...c };
                        if (typeof tmpChar.isActorLikenessPackaging === 'string') tmpChar.isActorLikenessPackaging = tmpChar.isActorLikenessPackaging === 'Y';
                        if (typeof tmpChar.isActorLikenessProduct === 'string') tmpChar.isActorLikenessProduct = tmpChar.isActorLikenessProduct === 'Y';
                        if (typeof tmpChar.isActorLikenessVoice === 'string') tmpChar.isActorLikenessVoice = tmpChar.isActorLikenessVoice === 'Y';
                        return tmpChar;
                    })
                },
                upcTableData: {
                    columns: [
                        {
                            label: 'Type',
                            key: 'upcEanIsbn'
                        },
                        {
                            label: 'Value',
                            key: 'upcValue'
                        },
                        {
                            label: 'Description',
                            key: 'upcDescription'
                        },
                        {
                            label: 'Retailer',
                            key: 'retailor'
                        }
                    ],
                    data: skuObj.upcOptOut === 'N' ? skuObj.upcs : []
                }
            };
            selectedSKUIndex.value = skuIndex;
            skuFormMode.value = 'EDIT';
        };

        const saveSKU = async () => {
            if (!selectedSKU.value.skuNo) {
                notificationsStack.value.push({ type: WARNING, message: ENTER_SKU_NUMBER });
                return;
            }

            // Validate the given SKU
            let validated = true;
            if (skuFormMode.value === 'NEW') validated = await isSkuValid();
            if (validated) {
                // Clean up the SKU Characters that dont have a name
                if (selectedSKU.value && selectedSKU.value.characterTableData) {
                    selectedSKU.value.characterTableData.data = selectedSKU.value.characterTableData.data.filter(e => e.characterName);
                }

                // Set the SKU Characters and UPC tables
                selectedSKU.value.characters = [...selectedSKU.value.characterTableData.data];
                selectedSKU.value.upcs = [...selectedSKU.value.upcTableData.data];

                // Either Add or update the sku
                if (skuFormMode.value === 'NEW') skuTableData.data.push(selectedSKU.value);
                else skuTableData.data.splice(selectedSKUIndex.value, 1, selectedSKU.value);

                // Save the submission form
                updateSkusInSubmissionForm();
                submissionFormValidations.skuUpcTable = '';
            }
        };

        const cancelSKU = () => {
            // if (skuTableData.data.length === 0)  setupNewSkuForm();
            // else
            if (mandateUpc() && submissionForm.skus[0]?.upcOptOut === 'N' && !submissionForm.skus[0]?.upcs?.length) {
                submissionFormValidations.upcGlobalValidation = true;
                submissionFormValidations.skuUpcTable = 'Please add at least one UPC/ISBN/EAN as there is a SKU present.';
            }
            resetSKUForm();
        };

        const resetSKUForm = () => {
            selectedSKU.value = {
                characterTableData: {
                    columns: [],
                    data: []
                },
                upcTableData: {
                    columns: [],
                    data: []
                }
            };
            selectedSKUIndex.value = null;
            skuFormMode.value = null;
        };

        const isAddSkuButtonActive = computed(() => skuTableData.data && (
            !skuTableData.data.length || licenseeCanHaveMultipleSkusPerSubmission.value
        ));

        const isDeleteSkuButtonActive = computed(() => skuTableData.data && skuTableData.data.some(tableObj => tableObj.selected));

        // const showSkuTable = computed(() => skuTableData.data && skuTableData.data.length);

        // -------------------------------
        // --------  SKU VALIDATION ------
        // -------------------------------

        // multiple sku validation bases of licensee ID
        const isValidatingSkus = ref(false);
        const isSkuValid = async () => {
            if (isValidatingSkus.value) return;
            try {
                isValidatingSkus.value = true;

                // First check the SKU
                try {
                    const validationResults = await store.dispatch('baSubmissions/validateSKU', {
                        bodyPayload: { skuNo: selectedSKU.value.skuNo, licenseeId: submissionForm.licenseeId }
                    });
                    if (!validationResults) throw new Error(SKU_VALIDATION_FAILED);

                    // Check is sku already exists
                    if (validationResults.isSkuExists) throw new Error(`[ ${selectedSKU.value.skuNo} ] already exists.`);
                    return true;
                } catch (err) {
                    console.error(`Problem in validating the SKU .. ${err}`);
                    notificationsStack.value.push({ type: ERROR, message: `${err}` });
                    return false;
                }
            } finally {
                isValidatingSkus.value = false;
            }
        };

        // -------------------------------
        // --------  SKU CHARACTERS ------
        // -------------------------------

        const handleCharacterSelection = (newCharacterName, i) => {
            const tmpCharacterRec = submissionForm.characters.find(e => e.characterName === newCharacterName);
            if (tmpCharacterRec) {
                const { characterId, characterName } = tmpCharacterRec;
                const curValue = selectedSKU.value.characterTableData.data[i];

                selectedSKU.value.characterTableData.data[i] = {
                    ...curValue,
                    characterId,
                    characterName,
                    isActorLikenessProduct: tmpCharacterRec.isActorLikenessProduct === 'Y',
                    isActorLikenessPackaging: tmpCharacterRec.isActorLikenessPackaging === 'Y',
                    isActorLikenessVoice: tmpCharacterRec.isActorLikenessVoice === 'Y'
                };
            }
        };

        const handleFlagSelection = (newValue, flagName, cIndex) => {
            selectedSKU.value.characterTableData.data[cIndex][flagName] = newValue;
        };

        const skuCharacterOptions = computed(() => {
            if (!submissionForm) return [];
            let selectedCharacterNames = [];
            if (selectedSKU.value && selectedSKU.value.characterTableData && selectedSKU.value.characterTableData.data) selectedCharacterNames = selectedSKU.value.characterTableData.data.map(c => c.characterName);

            return (submissionForm.characters || [])
                .filter(c => c?.characterName && !selectedCharacterNames.includes(c.characterName))
                .map(c => c?.characterName);
        });

        const isAddCharacterButtonActive = computed(() => skuCharacterOptions.value.length > 0);
        const isDeleteCharacterButtonActive = computed(() => selectedSKU.value && selectedSKU.value.characterTableData.data && selectedSKU.value.characterTableData.data.some(tableObj => tableObj.selected));

        const addCharacterTableRow = () => {
            selectedSKU.value.characterTableData.data.push({
                selected: false,
                characterId: null,
                characterName: '',
                isActorLikenessProduct: null,
                isActorLikenessPackaging: null,
                isActorLikenessVoice: null
            });
        };

        const deleteCharacterTableRows = () => {
            selectedSKU.value.characterTableData.data = selectedSKU.value.characterTableData.data.filter(tableObj => !tableObj.selected);
        };

        // -------------------------------
        // ----------  SKU UPC   ---------
        // -------------------------------

        const addUpcTableRow = () => {
            selectedSKU.value.upcTableData.data.push({
                selected: false,
                upcEanIsbn: '',
                value: '',
                description: '',
                retailer: ''
            });
        };

        const deleteUpcTableRows = () => {
            selectedSKU.value.upcTableData.data = selectedSKU.value.upcTableData.data.filter(tableObj => !tableObj.selected);
        };

        const isDeleteUpcButtonActive = computed(() => selectedSKU.value && selectedSKU.value.upcTableData.data && selectedSKU.value.upcTableData.data.some(tableObj => tableObj.selected));

        const upcTypeOptions = ref([
            { name: 'UPC', value: '1' },
            { name: 'EAN', value: '2' },
            { name: 'ISBN', value: '3' }
        ]);

        const handleUpcOptOut = (newValue) => {
            if (selectedSKU.value) {
                selectedSKU.value.upcOptOut = newValue;
                if (selectedSKU.value.upcOptOut === 'Y') {
                    selectedSKU.value.upcTableData.data = [];
                    submissionFormValidations.upcGlobalValidation = false;
                    // submissionFormValidations.skuUpcTable = '';
                } else {
                    selectedSKU.value.upcOptOutComments = '';
                }
            }
        };

        const handleUpcOptOutApproval = (approvalValue) => {
            if (selectedSKU.value) {
                selectedSKU.value.upcOptOutApproval = approvalValue;
                if (selectedSKU.value.upcOptOutApproval === 'N') {
                    selectedSKU.value.upcOptOutApprovalComments = '';
                }
            }
        };

        // -------------------------------
        // --------  CHECK FIELDS --------
        // -------------------------------

        const skuTableFieldState = computed(() => {
            // when submissionForm === new || submissionStatus === draft.   && metarial == POD ---> sku should be REQUIRED. (type should be STANDARD_SUBMISSION_TYPE)
            if ([GENERIC_SUBMISSION_TYPE, PRESS_RELEASE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return INVISIBLE;
            } else if (['DRAFT', 'NEW'].includes(submissionFormMode.value) && submissionForm.materialsSupplied === PRINT_ON_DEMAND_SAMPLE_METARIAL && submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE) {
                return REQUIRED;
            } else if ([STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return [PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) ? REQUIRED : EDITABLE;
            } else if ([OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return REQUIRED;
            } else {
                return EDITABLE;
            }
        });

        const skuCharactersFieldState = computed(() => {
            if ([GENERIC_SUBMISSION_TYPE, PRESS_RELEASE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return INVISIBLE;
            } else if (STANDARD_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                return [PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) ? REQUIRED : EDITABLE;
            } else {
                return EDITABLE;
            }
        });

        const upcOptOutFieldState = computed(() => {
            if ([STANDARD_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return EDITABLE;
            } else {
                return INVISIBLE;
            }
        });

        const optOutJustificationFieldState = computed(() => {
            if (selectedSKU?.value && selectedSKU.value.upcOptOut === 'Y') {
                if (STANDARD_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                    if ([PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) && (LICENSEE_STEP === submissionForm.currentStepName || submissionFormMode.value === 'NEW')) {
                        return REQUIRED;
                    } else if ([CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) && LICENSEE_STEP !== submissionForm.currentStepName) {
                        return REQUIRED;
                    } else {
                        return EDITABLE;
                    }
                } else if ([INTERACTIVE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return EDITABLE;
                } else {
                    return INVISIBLE;
                }
            } else {
                return INVISIBLE;
            }
        });

        const optOutApprovalFieldState = computed(() => {
            if (userPermissions.value.includes(SUBMISSION_FORM_OPT_APPROVAL_PERMISSION) && selectedSKU?.value && selectedSKU.value.upcOptOut === 'Y') {
                if ([STANDARD_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return (submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW') ? INVISIBLE : EDITABLE;
                } else {
                    return INVISIBLE;
                }
            } else {
                return INVISIBLE;
            }
        });

        const checkForRepeatedSkus = () => {
            if (!selectedSKU.value.skuNo || skuFormMode.value !== 'NEW') return false;
            const tmp = skuTableData.data.filter(s => s.skuNo === selectedSKU.value.skuNo);
            return tmp.length > 0;
        };

        const isSkuNumberRepeated = computed(() => checkForRepeatedSkus());

        const isSkuFormValid = computed(() => {
            const repeated = checkForRepeatedSkus();
            const allCondsChk = selectedSKU?.value.skuNo &&
            !repeated &&
            (!upcValidationErrors.value || upcValidationErrors.value.filter(e => e).length === 0) &&
            !submissionFormValidations.skuCharacters &&
            !submissionFormValidations.upcOptOutComments &&
            !submissionFormValidations.upcGlobalValidation;

            // console.log(`allCondsChk : ${allCondsChk}`)

            return allCondsChk;
        });

        watch(
            () => skuTableData.data,
            () => {
                if (skuTableData.data.length > 0) {
                    submissionFormValidations.skuTable = '';
                } else {
                    if ((['DRAFT', 'NEW'].includes(submissionFormMode.value) || submissionForm.submissionStatus === 'Draft') && submissionForm.materialsSupplied === PRINT_ON_DEMAND_SAMPLE_METARIAL && submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE) {
                        submissionFormValidations.skuTable = skuTableFieldState.value === REQUIRED && !skuTableData.data.length ? 'Please add at least one SKU' : '';
                        submissionFormValidations.upcGlobalValidation = true;
                    }
                }
            }
        );

        watch(
            () => submissionForm.materialsSupplied,
            () => {
                if (submissionForm.submissionTypeName !== OPA_SUBMISSION_TYPE) {
                    if ((['DRAFT', 'NEW'].includes(submissionFormMode.value) || submissionForm.submissionStatus === 'Draft') && submissionForm.materialsSupplied === PRINT_ON_DEMAND_SAMPLE_METARIAL && submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE) {
                        submissionFormValidations.skuTable = skuTableFieldState.value === REQUIRED && !skuTableData.data.length ? 'Please add at least one SKU' : '';
                        if (mandateUpc() && submissionForm.skus[0]?.upcOptOut === 'N' && !submissionForm.skus[0]?.upcs?.length) {
                            submissionFormValidations.upcGlobalValidation = true;
                            submissionFormValidations.skuUpcTable = 'Please add at least one UPC/ISBN/EAN as there is a SKU present.';
                        }
                    } else {
                        submissionFormValidations.skuTable = '';
                        submissionFormValidations.skuUpcTable = '';
                        submissionFormValidations.upcGlobalValidation = false;
                    }
                }
            }
        );

        watch(
            skuTableData,
            () => {
                // submissionForm.materialsSupplied === PRINT_ON_DEMAND_SUBMISSION_TYPE
                if (
                    submissionForm.submissionTypeName &&
                STANDARD_SUBMISSION_TYPE === submissionForm.submissionTypeName &&
                [PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName)
                ) {
                    submissionFormValidations.skuTable = skuTableFieldState.value === REQUIRED && !skuTableData.data.length
                        ? 'Please add at least one SKU' : '';
                    if (submissionForm.currentStepName === LICENSEE_STEP &&
                        submissionForm?.skus?.length > 0 && submissionForm?.skus[0]?.upcs?.length === 0 && submissionForm?.skus[0].upcOptOut === 'N'
                    ) {
                        submissionFormValidations.skuUpcTable = 'Please add at least one UPC/ISBN/EAN as there is a SKU present.';
                    } else {
                        submissionFormValidations.skuUpcTable = '';
                    }
                } else if ([OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    submissionFormValidations.skuTable = skuTableFieldState.value === REQUIRED && !skuTableData.data.length ? 'Please add at least one SKU' : '';
                }
            }
        );

        watch(
            selectedSKU,
            () => {
                if (selectedSKU.value) {
                    if (selectedSKU.value.skuNo) selectedSKU.value.skuNo = selectedSKU.value.skuNo.trim();

                    if (licenseeCanHaveMultipleSkusPerSubmission.value) {
                        submissionFormValidations.skuCharacters =
                        skuCharactersFieldState.value === REQUIRED &&
                        (selectedSKU.value.characterTableData?.data?.length === 0 || selectedSKU.value.characterTableData?.data?.filter(char => char.characterName).length === 0)
                            ? ADD_ATLEAST_ONE_CHARACTER : '';
                    }

                    submissionFormValidations.upcOptOutComments = optOutJustificationFieldState.value === REQUIRED && !selectedSKU.value.upcOptOutComments ? REQUIRED_FIELD : '';

                    upcValidationErrors.value = [];
                    submissionFormValidations.upcValueVaidation = [];

                    if (selectedSKU.value && selectedSKU.value?.upcTableData && selectedSKU.value?.upcTableData?.data.length > 0) {
                        selectedSKU.value.upcTableData.data.forEach((upcObj, i) => checkUpcValueFormat(upcObj, i));
                        submissionFormValidations.upcGlobalValidation = false;
                        // submissionFormValidations.skuUpcTable = '';
                    } else {
                        if (mandateUpc() && selectedSKU.value?.upcOptOut === 'N') {
                            submissionFormValidations.upcGlobalValidation = true;
                            submissionFormValidations.skuUpcTable = 'Please add at least one UPC/ISBN/EAN as there is a SKU present.';
                        } else {
                            submissionFormValidations.upcGlobalValidation = false;
                            // submissionFormValidations.skuUpcTable = '';
                        }
                    }
                }
            },
            {
                deep: true,
                immediate: true
            }
        );

        const mandateUpc = () => {
            if (
                submissionForm.submissionTypeName && STANDARD_SUBMISSION_TYPE === submissionForm.submissionTypeName &&
              (((['DRAFT', 'NEW'].includes(submissionFormMode.value) || submissionForm.submissionStatus === 'Draft') && submissionForm.materialsSupplied === PRINT_ON_DEMAND_SAMPLE_METARIAL) || submissionForm.currentStepName === LICENSEE_STEP) &&            
              [PRODUCTION_STAGE, CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName) 
            ) {
                return true;
            } else {
                return false;
            }
        };

        // -------------------------------
        // -----  UPCS VALUE FORMAT  -----
        // -------------------------------

        const upcValidationErrors = ref([]);

        const validateBarcode = (type, barcode) => {
            const code = `${barcode}`;
            const digits = () => /^\d{1,14}$/g.test(code);

            let validlengths = [];
            if (type === '1') validlengths = [12, 14];
            if (type === '2') validlengths = [13];

            if (!digits() || !validlengths.includes(code.length)) return false;

            return isValidBarcode(barcode);
        };

        const checkUpcValueFormat = (givenUpcObj, idx) => {
            if (!givenUpcObj) {
                upcValidationErrors.value[idx] = '';
                submissionFormValidations.upcValueVaidation[idx] = '';
                return;
            }

            switch (givenUpcObj.upcEanIsbn) {
            case '1':
                if (!validateBarcode(givenUpcObj.upcEanIsbn, givenUpcObj.upcValue)) {
                    upcValidationErrors.value[idx] = 'Invalid UPC format. Either a 12 or 14 digit identifier is required.';
                    submissionFormValidations.upcValueVaidation[idx] = REQUIRED_FIELD;
                } else {
                    submissionFormValidations.upcValueVaidation[idx] = '';
                }
                break;
            case '2':
                if (!validateBarcode(givenUpcObj.upcEanIsbn, givenUpcObj.upcValue)) {
                    upcValidationErrors.value[idx] = 'Invalid EAN format. A 13 digit identifier is required.';
                    submissionFormValidations.upcValueVaidation[idx] = REQUIRED_FIELD;
                } else {
                    submissionFormValidations.upcValueVaidation[idx] = '';
                }
                break;
            case '3':
                if (!givenUpcObj.upcValue || givenUpcObj.upcValue.length === 0) {
                    upcValidationErrors.value[idx] = 'ISBN value is required';
                    submissionFormValidations.upcValueVaidation[idx] = REQUIRED_FIELD;
                } else {
                    submissionFormValidations.upcValueVaidation[idx] = '';
                }
                break;
            default:
                upcValidationErrors.value[idx] = 'Type and Value are required';
                submissionFormValidations.upcValueVaidation[idx] = REQUIRED_FIELD;
            }
        };

        const isValidBarcode = (barcode) => {
            // We only allow correct length barcodes
            if (!barcode.match(/^(\d{8}|\d{12,14})$/)) {
                return false;
            }

            const paddedValue = barcode.padStart(14, '0');

            let result = 0;
            for (let i = 0; i < paddedValue.length - 1; i += 1) {
                result += parseInt(paddedValue.charAt(i), 10) * ((i % 2 === 0) ? 3 : 1);
            }

            return ((10 - (result % 10)) % 10) === parseInt(paddedValue.charAt(13), 10);
        };

        // -------------------------------
        // -----  STYLE NAME CHANGE  -----
        // -------------------------------

        watch(
            () => ({ styleGuideName: submissionForm.styleGuideName, submissionId: submissionForm.submissionId }),
            async (__new, __old) => {
                if (!__new.submissionId || __new.submissionId === __old.submissionId) {
                    skuTableData.data = [];
                    updateSkusInSubmissionForm();
                }
            }
        );

        const makeCharacterTableColumns = (sgName) => {
            if (sgName?.toLowerCase().includes('movie')) {
                return [
                    {
                        label: 'Style Guide Character',
                        key: 'characterName'
                    },
                    {
                        label: 'Actor Likeness on Product',
                        key: 'isActorLikenessProduct'
                    },
                    {
                        label: 'Actor Likeness on Packaging',
                        key: 'isActorLikenessPackaging'
                    },
                    {
                        label: 'Actor Voice',
                        key: 'isActorLikenessVoice'
                    }
                ];
            } else {
                return [
                    {
                        label: 'Style Guide Character',
                        key: 'characterName'
                    }
                ];
            }
        };

        const updateSkusInSubmissionForm = () => {
            skuTableData.data = skuTableData.data.map(s => {
                s.characters = (s.characterTableData?.data || s.characters || []).map(c => {
                    const tmpChar = { ...c };

                    if (typeof tmpChar.isActorLikenessPackaging === 'boolean') tmpChar.isActorLikenessPackaging = tmpChar.isActorLikenessPackaging === true ? 'Y' : '';
                    if (typeof tmpChar.isActorLikenessProduct === 'boolean') tmpChar.isActorLikenessProduct = tmpChar.isActorLikenessProduct === true ? 'Y' : '';
                    if (typeof tmpChar.isActorLikenessVoice === 'boolean') tmpChar.isActorLikenessVoice = tmpChar.isActorLikenessVoice === true ? 'Y' : '';
                    return tmpChar;
                });

                s.upcs = s.upcTableData?.data || s.upcs || [];

                delete s.characterTableData;
                delete s.upcTableData;

                return s;
            });
            updateSubmissionForm({ skus: skuTableData.data });

            cancelSKU();
        };

        // -------------------------------
        // ----------  MOUNTED  ----------
        // -------------------------------

        const licenseeCanHaveMultipleSkusPerSubmission = ref(false);
        onMounted(async () => {
            // if (selectedSKU.value === null && !submissionForm.skus?.length) setupNewSkuForm();

            try {
                const resp = await store.dispatch('baSubmissions/validateMultipleSKU', {
                    bodyPayload: { licenseeId: submissionForm.licenseeId }
                });
                if (resp && resp.multipleSkusAllowed) licenseeCanHaveMultipleSkusPerSubmission.value = true;
            } catch (e) {}
        });

        const numberOfSampleReceivedFieldState = computed(() => {
            if ([STANDARD_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return (submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW') ? INVISIBLE : EDITABLE;
            } else if (submissionForm.submissionTypeName === OPA_SUBMISSION_TYPE) {
                return EDITABLE;
            } else {
                return INVISIBLE;
            }
        });

        const disabledSaveSkuFieldState = computed(() => {
            if (!isSkuFormValid.value) {
                return VIEW_ONLY;
            } else if (
                licenseeCanHaveMultipleSkusPerSubmission.value &&
                [OPA_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName) &&
                (selectedSKU.value?.characterTableData?.data.length === 0 ||
                selectedSKU.value?.characterTableData?.data[0].characterName === '')
            ) {
                return VIEW_ONLY;
            } else {
                return EDITABLE;
            }
        });

        /* checking this watcher when creating similar submission */
        watch(
            () => submissionForm.skus,
            () => {
                if (submissionForm.skus.length === 0 && skuTableData && skuTableData.data) skuTableData.data = [];
            }
        );

        return {
            // SKU's
            selectedSKU,
            selectedSKUIndex,
            skuTableData,
            setupNewSkuForm,
            deleteSKU,
            editSKU,
            isValidatingSkus,
            saveSKU,
            cancelSKU,
            isDeleteSkuButtonActive,
            isAddSkuButtonActive,
            // showSkuTable,

            // character section
            skuCharacterOptions,
            isAddCharacterButtonActive,
            addCharacterTableRow,
            deleteCharacterTableRows,
            isDeleteCharacterButtonActive,
            styleGuideCharacterData,

            // upc section
            addUpcTableRow,
            deleteUpcTableRows,
            isDeleteUpcButtonActive,
            upcTypeOptions,
            handleUpcOptOut,
            handleUpcOptOutApproval,
            generateTableSlotName,
            // SKU validations
            REQUIRED,
            INVISIBLE,
            submissionFormValidations,
            skuTableFieldState,
            skuCharactersFieldState,
            optOutJustificationFieldState,
            optOutApprovalFieldState,
            upcValidationErrors,
            handleCharacterSelection,
            handleFlagSelection,
            skuFormMode,
            isSkuNumberRepeated,
            isSkuFormValid,
            licenseeCanHaveMultipleSkusPerSubmission,
            upcOptOutFieldState,
            numberOfSampleReceivedFieldState,
            OPA_SUBMISSION_TYPE,
            INTERACTIVE_SUBMISSION_TYPE,
            submissionForm,
            disabledSaveSkuFieldState,
            VIEW_ONLY
        };
    }
};
</script>
